import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

/**
 * Common environment variables for all apps.
 */
export const globalEnv = createEnv({
  server: {
    /**
     * Environment the app is running in. This is defined by the SST `$stage` variable in CI.
     * - `'prod'` for production
     * - `'pr-XXX'` for pull request deployments
     * - `undefined` for local development
     */
    APP_ENV: z.string().optional(),

    /**
     * JWT secret for microservice communication.
     */
    STREETFERRET_MICROSERVICE_API_SECRET: z.string(),
  },
  client: {
    /**
     * URL for the tile server. Use this as a prefix for custom tile URLs. Defaults to prod if not set.
     */
    NEXT_PUBLIC_TILE_SERVER_URL: z
      .string()
      .default('https://tiles.streetferret.com'),
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_TILE_SERVER_URL: process.env.NEXT_PUBLIC_TILE_SERVER_URL,
  },
  skipValidation:
    !!process.env.CI || process.env.npm_lifecycle_event === 'lint',
});
