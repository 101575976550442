import { env } from './env';

/**
 * Map font and glyph URL
 */
export const FONT_URL =
  'https://StreetFerret.github.io/fontstack66/{fontstack}/{range}.pbf';

/**
 * Satellite tile source URL
 */
export const SATELLITE_TILE_URL =
  'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';

/**
 * StreetFerret custom tile server URL
 */
export const TILESERVER_URL = env.NEXT_PUBLIC_TILE_SERVER_URL;
