import { globalEnv } from '@streetferret/core/global-env';
import { createEnv } from '@t3-oss/env-nextjs';

export const env = createEnv({
  extends: [globalEnv],
  server: {},
  client: {},
  experimental__runtimeEnv: {},
  skipValidation:
    !!process.env.CI || process.env.npm_lifecycle_event === 'lint',
});
