'use client';

import { Source } from 'react-map-gl';
import { TILESERVER_URL } from '../../constants';

export const CITY_STREETS_SOURCE_ID = 'city-streets';
interface CityStreetsSourceProps {
  children: React.ReactNode;
  cityId: number;
  version: number;
}
export function CityStreetsSource({
  children,
  cityId,
  version,
}: CityStreetsSourceProps) {
  return (
    <Source
      id={CITY_STREETS_SOURCE_ID}
      type="vector"
      url={`${TILESERVER_URL}/city/${cityId}/${version}/style.json`}>
      {children}
    </Source>
  );
}
