'use client';

import { useMemo } from 'react';
import { Layer, type LineLayer } from 'react-map-gl';
import { useMapTheme } from '../../context';
import { lineWidthScale, PROGRESS_LINE_WIDTH } from '../../utils/layer-utils';

interface CityStreetsLayerProps {
  /**
   * The ID of the tile source to use for these layers
   */
  sourceId: string;
}

export function CityStreetsLayer({ sourceId }: CityStreetsLayerProps) {
  const { theme } = useMapTheme();

  const layer: LineLayer = useMemo(
    () => ({
      source: sourceId,
      'source-layer': 'progress',
      type: 'line',
      layout: {
        visibility: 'visible',
        'line-cap': 'round',
        'line-join': 'round',
      },
      minzoom: 7,
      id: 'city-streets',
      paint: {
        'line-color': theme.incomplete,
        'line-width': lineWidthScale(PROGRESS_LINE_WIDTH),
      },
    }),
    [sourceId, theme],
  );

  return <Layer beforeId="_road_overlays" {...layer} />;
}
