'use client';

import { useMemo } from 'react';
import { Layer, type LineLayer } from 'react-map-gl';
import { useMapTheme } from '../../context';

interface CityBoundaryLayerProps {
  /**
   * The ID of the tile source to use for these layers
   */
  sourceId: string;
}

export function CityBoundaryLayer({ sourceId }: CityBoundaryLayerProps) {
  const { theme } = useMapTheme();

  const layers: LineLayer[] = useMemo(() => {
    const createBoundaryLayer = (
      id: string,
      lineColor: string,
      lineWidth: number,
    ): LineLayer => ({
      source: sourceId,
      'source-layer': 'boundary',
      type: 'line' as const,
      layout: {
        visibility: 'visible' as const,
      },
      minzoom: 7,
      id,
      paint: {
        'line-color': lineColor,
        'line-width': lineWidth,
      },
    });

    return [
      createBoundaryLayer('city-bounds-casing', '#FFD700', 7), // Gold color
      createBoundaryLayer('city-bounds-outline', theme.bounds, 1),
    ];
  }, [theme, sourceId]);

  return (
    <>
      {layers.map(layer => (
        <Layer key={layer.id} beforeId="_background_underlay" {...layer} />
      ))}
    </>
  );
}
